const BASE_URL = 'https://test.divo.kr/';

export const LOGIN_URL = BASE_URL + 'auth/login/';
export const AUTH_URL = BASE_URL + 'auth/register/message/';
export const REGISTER_URL = BASE_URL + 'auth/register/telephone/';
export const CORPLIST_URL = BASE_URL + 'auth/user/corporation';
export const OLD_AUTH_URL = BASE_URL + 'auth/account/find/';
export const RESET_URL = BASE_URL + 'auth/account/reset/';
export const CORP_AUTH_URL = BASE_URL + 'corporation/permission/?corp_id=';

export const RANK_OM_URL = BASE_URL + 'omrank/omrank?id=';
export const RANK_SS_URL = BASE_URL + 'omrank/simpleSummary?id=';
export const RANK_BS_URL = BASE_URL + 'omrank/boundSearch';

export const IM_DRAW_URL = BASE_URL + 'map/bound/';
export const IM_KW_URL = BASE_URL + 'map/bound/keyword?area=';
export const IM_PL_URL = BASE_URL + 'map/bound/place?area=';
export const IM_RG_URL = BASE_URL + 'map/bound/region?area=';
export const IM_PLKW_URL = BASE_URL + 'map/bound/place/keyword?id=';
export const IM_KS_URL = BASE_URL + 'map/bound/keywordstat?keyword=';
export const IM_QUERY_URL = BASE_URL + 'map/bound/search?query=';
export const IM_RANK_URL = BASE_URL + 'map/bound/keyword/rank?keyword=';
export const IM_CAT_URL = BASE_URL + 'map/bound/category/rank?area=';
export const IM_CS_URL = BASE_URL + 'map/bound/categorystat?keyword=';
