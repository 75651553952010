import GlobalStyles from '@/styles/GlobalStyles';
import { useRoutes } from "react-router-dom";
import { Chart as ChartJS, ArcElement, BarElement, CategoryScale, Filler, Legend, LinearScale, LineElement, PointElement, RadialLinearScale, Tooltip } from "chart.js";
import Login from "@/Login";
import Map from "@/Map";

ChartJS.register(
  RadialLinearScale,
  CategoryScale,
  LinearScale,
  PointElement,
  ArcElement,
  LineElement,
  BarElement,
  Filler,
  Tooltip,
  Legend,
);

ChartJS.defaults.font.family = 'Pretendard';
ChartJS.defaults.plugins.legend.labels.usePointStyle = true;

const App = () => {
  const routes = [
    {
      path: '/',
      element: <Map />
    },
    {
      path: '/login',
      element: <Login />
    }
  ]
  return (
    <>
      <GlobalStyles />
      {useRoutes(routes)}
    </>
  );
}

export default App;
