export const mapLineOptions = (unit = '', reverse = false, isWhite = false) => {
  let options = {
    responsive: true,
    interaction: {
      intersect: false,
      mode: 'index'
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        grid: {
          drawBorder: false
        },
        ticks: {
          font: {
            size: 10
          },
          maxTicksLimit: 7,
          padding: 7,
          callback: (val, index) => val >= 10000 ? (val/10000 + '만') : val >= 1000 ? (val/1000 + '천') : val
        },
        reverse: reverse
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: tooltipItem => tooltipItem.dataset.label + ': ' + tooltipItem.formattedValue + unit
        }
      }
    },
    elements: {
      line: {
        tension: 0.2
      }
    },
    pointRadius: 0.01,
    pointHoverRadius: 3,
    borderWidth: 2,
    pointHoverBackgroundColor: 'white',
  }

  if(isWhite){
    options = { ...options, 
      scales: {
        ...options.scales,
        y: {
          ...options.scales.y,
          grid: {
            borderColor: 'rgba(245, 245, 247, 0.3)',
            color: 'rgba(245, 245, 247, 0.3)',
            tickColor: 'rgba(245, 245, 247, 0.3)',
            ...options.scales.y.grid
          },
          ticks: {
            color: 'rgba(245, 245, 247, 0.8)',
            ...options.scales.y.ticks
          }
        }
      },
      color: 'rgba(245, 245, 247, 0.8)'
    }
  }

  return options;
};

export const mapBarOptions = (unit, thin = false, isWhite = true) => {
  let options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          label: tooltipItem => tooltipItem.dataset.label + ': ' + tooltipItem.formattedValue + unit
        }
      }
    },
    maxBarThickness: thin ? 7 : 15,
    interaction: {
      intersect: false,
      mode: 'index'
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        grid: {
          drawBorder: false,
        },
        ticks: {
          font: {
            size: 10
          },
          maxTicksLimit: 7,
          padding: 7
        },
      }
    },
  }
  if(isWhite){
    options = {
      ...options,
      scales: {
        ...options.scales,
        y: {
          ...options.scales.y,
          grid: {
            ...options.scales.y.grid,
            borderColor: 'rgba(245, 245, 247, 0.3)',
            color: 'rgba(245, 245, 247, 0.3)',
            tickColor: 'rgba(245, 245, 247, 0.3)',
          },
          ticks: {
            ...options.scales.y.ticks,
            color: 'rgba(245, 245, 247, 0.8)'
          }

        }
      }
    }
  }
  return options;
};

export const mapPieOptions = () => {
  let options = {
    responsive: true,
    plugins: {
      legend: {
        display: false
      },
      datalabels: {
        display: true,
        formatter: (val, ctx) => {
          return val>=3 ? ctx.chart.data.labels[ctx.dataIndex] : null;
        },
        color: '#f5f5f7',
        font:{
          size: 10
        }
      },
      tooltip: {
        callbacks: {
          label: tooltipItem => tooltipItem.label + ': ' + tooltipItem.formattedValue + '%'
        }
      }
    },
  };
  return options;
}

export const salesCompareData = [
  {
    labels: ['1월 2주차', '1월 3주차', '1월 4주차', '1월 5주차', '2월 1주차', '2월 2주차', '2월 3주차'],
    datasets: [
      {
        label: '점심 매출액',
        data: [40, 30, 40, 50, 30, 20, 40],
        borderColor: 'rgba(142, 202, 230)', 
        backgroundColor: 'rgba(142, 202, 230, 0.1)',
        fill: 'origin'
      },
      {
        label: '저녁 매출액',
        data: [20, 10, 60, 40, 30, 30, 80],
        borderColor: 'rgba(255, 183, 3)', 
        backgroundColor: 'rgba(255, 183, 3, 0.1)',
        fill: 'origin'
      },
    ]
  },
  {
    labels: ['1월 1주차', '1월 2주차', '1월 3주차', '1월 4주차', '1월 5주차', '2월 1주차', '2월 2주차', '2월 3주차', '2월 4주차', '3월 1주차', '3월 2주차', '3월 3주차'],
    datasets: [
      {
        label: '매출액',
        data: [10, 90, 30, 40, 30, 20, 30, 50, 10, 20, 30, 40],
        borderColor: 'rgba(0, 99, 178)', 
        backgroundColor: 'rgba(0, 99, 178, 0.5)',
        fill: 'origin'
      },
    ]
  },
  {
    labels: ['2021.02', '2021.03', '2021.04', '2021.05',' 2021.06', '2021.07', '2021.08', '2021.09', '2021.10', '2021.11', '2021.12', '2022.01'],
    datasets: [
      {
        label: '매출액',
        data: [10, 20, 30, 40, 50, 40, 30, 20, 10, 20, 30, 40],
        borderColor: 'rgba(0, 99, 178)', 
        backgroundColor: 'rgba(0, 99, 178, 0.5)',
        fill: 'origin'
      },
    ]
  },
];

export const changeZoom = code => {
  if(code.length === 2) return 11;
  else if(code.length === 5) return 9;
  else return 6;
}

export const getPlaceOverlay = place => {
  return `
    <style>
      #close-overlay:hover{
        cursor: pointer;
      }
      #show-detail:hover{
        cursor: pointer;
        text-decoration: underline;
      }
    </style>
    <div style="display: flex; flex-flow: column; min-width: 240px; color: #263b4d; background: rgba(255, 255, 255, 0.5); padding: 20px 15px; box-shadow: 2px 4px 12px rgb(38 59 77 / 30%); border-radius: 5px; backdrop-filter: saturate(180%) blur(40px); border: 1px solid rgba(255, 255, 255, 0.18); position: relative;">
      <div style="display: flex; justify-content: space-between; margin-bottom: 15px; align-items: start;">
        <div style="display: flex; font-weight: bold; align-items: end;">
          <a href="${place.url}" target="_blank" style="color: inherit; text-decoration: none;">${place.name}</a>
          <div style="margin-left: 5px; font-size: 12px; font-weight: normal;">${place.category}</div>
        </div>
        <div style="font-size: 12px; padding: 0 0 5px 5px;" id="close-overlay"><i class="fas fa-times"></i></div>
      </div>
      <div style="font-size: 12px; color: #3166a1; margin-bottom: 5px;">
        블로그 리뷰 ${place.blogReviewNum}개
      </div>
      <div style="font-size: 12px; color: #3166a1; margin-bottom: 30px;">
        방문자 리뷰 ${place.visitorReviewNum}개
      </div>
      <div style="display: flex; justify-content: space-between; align-items: end;">
        <div style="font-family: 'Montserrat', 'Pretendard'; font-weight: bold; font-size: 18px;">
          ${place.rank}위<span style="font-size: 12px;">(상위 ${place.ratio}%)</span>
        </div>
        <div style="font-size: 12px; color: #3166a1;" id="show-detail"><i class="fas fa-external-link-alt"></i></div>
      </div>
    </div>
    <div style="height: 10px; width: 10px; background: rgba(255, 255, 255, 0.5); margin: 0 auto; transform: translateY(-5px) rotate(45deg); backdrop-filter: blur(12px); border-right: 1px solid rgba(255, 255, 255, 0.18); border-bottom: 1px solid rgba(255, 255, 255, 0.18);"></div>
  `;
};

export const regionType = (code) => {
  if(code === 0) return '전국';
  else if(code.length === 2) return '시·도 ';
  else if(code.length === 5) return '시·군·구 ';
  else if(code.length === 8) return '읍·면·동 ';
  else return '세부 ';
};

export const defaultQuery = {
  type: 'region',
  code: 0,
  name: '전국',
};