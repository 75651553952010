import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { LOGIN_URL } from "@api";
import styled from "styled-components";

const Login = () => {
  const [ id, setId ] = useState('');
  const [ pw, setPw ] = useState('');
  const navigate = useNavigate();

  const handleLogin = () => {
    const body = {
      email: id,
      password: pw
    };
    axios.post(LOGIN_URL, body).then(res => {
      if(res.data.token && res.data.isAdmin){
        localStorage.clear();
        localStorage.setItem('token', res.data.token);
        navigate('/');
      }
      else{
        alert('일치하는 계정이 없거나 권한이 없습니다', 1);
        setPw('');
        localStorage.clear();
      }
    }).catch(e => console.log('POST Error'));
  };

  const onKeyPress = (e) => {
    if(e.key === 'Enter') handleLogin();
  };

  return (
    <S.Box>
      <S.Input value={id} onChange={e => setId(e.target.value)} />
      <S.Input value={pw} onChange={e => setPw(e.target.value)} type="password" onKeyPress={onKeyPress} />
      <S.Button onClick={handleLogin}>로그인</S.Button>
    </S.Box>
  );
}

const S = {};

S.Box = styled.div`
  display: flex;
  flex-flow: column;
  width: 300px;
  margin: auto;
  align-items: center;
`;

S.Input = styled.input`
  margin-bottom: 10px;
  touch-action: manipulation;
  border-radius: 4px;
  padding: 6px 12px;
  height: 32px;
  box-shadow: none !important;
  font-weight: 500;
  line-height: 20px;
  font-size: 13px;
  -webkit-appearance: none;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: rgba(0, 0, 0, 0.85);
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  transition: all 0.3s;
  overflow: visible;

  &:hover{
    border-color: #40a9ff;
    border-right-width: 1px;
  }

  &:focus{
    outline: none;
  }

  &::selection{
    color: #fff;
    background: #1890ff;
  }
`;

S.Button = styled.button`
  height: 32px;
  width: 64px;
  padding: 0 8px;
  line-height: 20px;
  font-size: 13px;
  font-weight: 500;
  box-shadow: none;
  border-radius: 4px;
  border: 1px solid transparent;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  background: #fff;
  border-color: #d9d9d9;
  cursor: pointer;

  &:hover{
    color: #40a9ff;
    border-color: #40a9ff;
  }
`;

export default Login;